import React from "react";
import { NavLink } from "react-router-dom";
import { useProfileContext } from "../../App";
import {PLAY_BUTTON_CLICK_SOUND} from '../../constants/audio'
import { Tickets, Home, Result } from "../../constants/assets/Icons";

function BottomNavbar() {
    // const {agentPhone} = useProfileContext()
    return (
        <div className="fixed w-full bottom-0 z-10 left-0 right-0 bg-[#030e03] backdrop-blur-3xl sm:hidden">
            <nav className="flex h-20 w-full justify-around items-center ">
                <NavLink
                    className="flex justify-center h-auto w-[50px] flex-col hover:text-orange-500"
                    onClick={() => PLAY_BUTTON_CLICK_SOUND()}
                    to="/"
                >
                    <Home />
                    <p className="text-sm font-mono text-center oleo-script ">Games</p>
                </NavLink>

                <NavLink
                    className="flex justify-center h-auto w-[50px] flex-col hover:text-orange-500"
                    onClick={() => PLAY_BUTTON_CLICK_SOUND()}
                    to="/bid-history"
                >
                    <Tickets />
                    <p className="text-sm font-mono text-center oleo-script ">Bids</p>
                </NavLink>

                {/* <NavLink
                    className=" flex justify-center h-[75px] items-center"
                    onClick={() => PLAY_BUTTON_CLICK_SOUND()}
                    to={`https://wa.me/${agentPhone ?? '+9779707559773'}?text=I%27m%20interested%20in%20your%20games`}
                >
                    <img alt="img" className="h-[60px] w-[60px]" src="./assets/img/contact.png" />
                </NavLink> */}

                <NavLink
                    className="flex justify-center h-auto w-[50px] flex-col hover:text-orange-500"
                    onClick={() => PLAY_BUTTON_CLICK_SOUND()}
                    to="/result"
                >
                    <Result />
                    <p className="text-sm font-mono text-center oleo-script ">Results</p>
                </NavLink>
                <div className="w-[50px]" />

                {/* <NavLink
                    className="flex justify-center h-[70px] w-[50px] flex-col  hover:text-orange-500"
                    target="_blank"
                    onClick={() => PLAY_BUTTON_CLICK_SOUND()}
                    to="https://wa.me/+9779709372855"
                >
                    <img alt="img" className=" w-[35px] justify-center ml-2" src="./assets/img/support.png" />
                    <p className="text-xs font-mono text-center oleo-script ">Support</p>
                </NavLink> */}
            </nav>
        </div>
    );
}

export default BottomNavbar;
